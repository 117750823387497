<dl class="collapsable-list" *ngIf="contentItems" [style.--initial-height]="collapsableHeight">
  <ng-container *ngFor="let year of contentItems | reverse; let first = first">
    <dt class="collapsable-list__head" appCollapsableToggle [isOpen]="first">{{ year.year }}</dt>
    <dd class="collapsable-list__body">
      <ul class="details-list type--1" id="award-body">
        <li *ngFor="let item of year.items">
          {{ item.title }}
        </li>
      </ul>
    </dd>
  </ng-container>
</dl>
