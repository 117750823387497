<main class="content-detail background--light">
  <div class="block-mobile-nav-space"></div>

  <header class="content-detail__header">
    <div class="content-detail__header__container">
      <h1 class="content-detail__header__title">{{ 'contact-page-title' | fromDictionary }}</h1>
    </div>
  </header>

  <div class="content-detail__body padding-top--0">
    <div class="contact">
      <article class="contact__column" *ngFor="let location of locations">
        <h1 class="contact__column__title">{{ 'location-'+location.dictionaryName+'-title' | fromDictionary }}</h1>
        <p>
          <ng-container *ngIf="dictionaryTextFound('location-'+location.dictionaryName+'-phone' | fromDictionary) as phone">
            {{ 'location-'+location.dictionaryName+'-phone' | fromDictionary }}
            <br />
            <br />
          </ng-container>

          <ng-container *ngIf="dictionaryTextFound('location-'+location.dictionaryName+'-email' | fromDictionary) as email">
            <a [href]="'location-'+location.dictionaryName+'-email' | fromDictionary">
              {{ 'location-'+location.dictionaryName+'-email' | fromDictionary }}
            </a>
            <br />
            <br />
          </ng-container>

          <ng-container *ngIf="dictionaryTextFound('location-'+location.dictionaryName+'-addressline-1' | fromDictionary) as addressLine">
            {{ 'location-'+location.dictionaryName+'-addressline-1' | fromDictionary }}
            <br />
          </ng-container>
          <ng-container *ngIf="dictionaryTextFound('location-'+location.dictionaryName+'-addressline-2' | fromDictionary) as addressLine">
            {{ 'location-'+location.dictionaryName+'-addressline-2' | fromDictionary }}
            <br />
          </ng-container>
          <ng-container *ngIf="dictionaryTextFound('location-'+location.dictionaryName+'-addressline-3' | fromDictionary) as addressLine">
            {{ 'location-'+location.dictionaryName+'-addressline-3' | fromDictionary }}
            <br />
          </ng-container>
          <ng-container *ngIf="dictionaryTextFound('location-'+location.dictionaryName+'-addressline-4' | fromDictionary) as addressLine">
            {{ 'location-'+location.dictionaryName+'-addressline-4' | fromDictionary }}
          </ng-container>
        </p>
      </article>

      <article class="contact__column">
        <h1 hidden class="contact__column__title">Enquiries</h1>

        <section class="contact__column__section" *ngFor="let dep of [1,2,3,4,5]">
          <h1 class="contact__column__section__title">{{ 'contact-departement-'+dep+'-title' | fromDictionary }}</h1>
          <a *ngIf="dep < 4" [href]="'mailto:'+('contact-departement-'+dep+'-email' | fromDictionary)">{{'contact-departement-'+dep+'-email' | fromDictionary}}</a>
          <a *ngIf="dep >= 4" [href]="('contact-departement-'+dep+'-link' | fromDictionary)" [target]="dep === 5 ? '_blank':'_self'">{{'contact-departement-'+dep+'-link-text' | fromDictionary}}</a>
        </section>
      </article>
    </div>
  </div>
</main>

<figure class="content-detail" class="background--dark">
  <header hidden><h1>Map</h1></header>
  <agm-map
    #map
    style="height: 62vh; min-height: 300px; max-height: 700px"
    [mapTypeId]="options.type"
    [scrollwheel]="options.scrollwheel"
    [zoomControl]="options.zoomControl"
    [disableDoubleClickZoom]="options.disableDoubleClickZoom"
    [disableDefaultUI]="options.disableDefaultUI"
    [mapTypeControl]="options.mapTypeControl"
    [latitude]="options.center.latitude"
    [longitude]="options.center.longitude"
    [streetViewControl]="options.streetViewControl"
    (mapReady)="getMap($event)"
    [zoom]="options.zoom"
  >
    <agm-marker
      *ngFor="let location of locations"
      [iconUrl]="options.iconUrl"
      [latitude]="location.latitude"
      [longitude]="location.longitude"
    >
      <agm-snazzy-info-window
        (afterClose)="recenter()"
        [closeWhenOthersOpen]="options.infoWindow.closeWhenOthersOpen"
        [placement]="options.infoWindow.placement"
        [maxWidth]="options.infoWindow.maxWidth"
        [maxHeight]="options.infoWindow.maxHeight"
        [border]="options.infoWindow.border"
        [borderRadius]="options.infoWindow.borderRadius"
        [shadow]="options.infoWindow.shadow"
        [showCloseButton]="options.infoWindow.showCloseButton"
        [placement]="options.infoWindow.placement"
        [isOpen]="options.infoWindow.isOpen"
        #infoWindow
      >
        <ng-template>
          <article class="infowindow-content">
            <a
              class="hover--underline"
              target="_blank"
              [href]="'https://www.google.com/maps?saddr&daddr=(' + location.latitude + ',' + location.longitude + ')'"
            >
              <strong *ngIf="location?.title">{{ location.title }}</strong>
            </a>
          </article>
        </ng-template>
      </agm-snazzy-info-window>
    </agm-marker>
  </agm-map>
</figure>

<footer class="content-detail background--dark">
  <header class="content-detail__header text-center">
    <div class="content-detail__header__container">
      <h1 class="content-detail__header__title">{{ 'contact-follow-us-title' | fromDictionary }}</h1>
    </div>
  </header>

  <div class="content-detail__body padding-top--0">
    <!-- TEMP: HIDDEN BECAUSE SIGNUP FORM IS NOT WORKING ANYMORE -->
    <!-- <div class="text-intro text-center">
      Never miss any MVRDV updates with our amazing newsletter. We send out Press Releases concerning our latest work. P.S. We
      try to avoid spamming you and only send out important news, not more than twice a month.
    </div> -->

    <!-- TEMP: HIDDEN BECAUSE SIGNUP FORM IS NOT WORKING ANYMORE -->
    <!-- <app-signup-newsletter></app-signup-newsletter> -->

    <ul class="social-list">
      <li *ngFor="let item of socials">
        <a class="button type--icon-only" [title]="item.channel" rel="noopener noreferrer" target="_blank" [href]="item.url">
          <app-icon [anchor]="item.icon"></app-icon>
        </a>
      </li>
      <li>
        <a
          class="button type--icon-only"
          rel="noopener noreferrer"
          href="#add-on-wechat"
          (click)="$event.preventDefault(); modal.open('add-on-wechat')"
        >
          <app-icon [anchor]="'icon-wechat'"></app-icon>
        </a>
      </li>
    </ul>
  </div>
</footer>

<app-modal [id]="'add-on-wechat'">
  <figure class="modal__wechat text-intro">
    <a rel="noopener noreferrer" target="_blank " [href]="'contact-wechat-qr-link' | fromDictionary" class="inline-block">
      <img
        loading="lazy"
        src="https://mvrdv.com/media/uploads/mvrdv-wechat.png"
      />
    </a>
    <br />
    <figcaption class="modal__wechat__text">
      <strong>{{ 'contact-wechat-qr-title' | fromDictionary }}</strong>
      <br />
      <p>{{ 'contact-wechat-qr-text' | fromDictionary }}</p>
      <a rel="noopener noreferrer" target="_blank" [href]="'contact-wechat-qr-link' | fromDictionary">
        {{ 'contact-wechat-qr-link' | fromDictionary }}
      </a>
    </figcaption>
  </figure>
</app-modal>
